"use client";

import { tw } from "@/utils/helpers";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { Star } from "react-feather";
import { useState } from "react";

interface TileProps {
  src: string;
  name: string;
  path: string;
  isFavorite: boolean;
  status: string;
}

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength - 3) + "...";
}

export function FeatureTile({ src, name, path, isFavorite, status }: TileProps) {
  const router = useRouter();
  const [favorite, setFavorite] = useState(isFavorite);

  const handleClick = () => {
    if (status !== "coming") {
      router.push(path);
    }
  };

  const onToggleFavorite = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFavorite((prev) => !prev);
  };

  const isComingSoon = status === "coming";

  return (
    <div
      className={tw(
        "flex flex-col rounded-[40px] overflow-hidden",
        isComingSoon ? "opacity-70" : "hover:cursor-pointer hover:-translate-y-1 transition-all",
        "bg-white shadow-md"
      )}
      onClick={handleClick}
    >
      <div className="relative border-x-2 border-pink-200">
        <Image className={tw("object-cover w-full h-auto")} width={339} height={408} src={src} alt={name} />
        <button
          className={tw(
            "absolute top-4 right-4 w-8 h-8 rounded-full",
            "flex items-center justify-center",
            "shadow-md",
            favorite ? "text-yellow-500" : "text-black",
            "hover:text-yellow-500 transition-colors"
          )}
          onClick={onToggleFavorite}
        >
          <Star className="w-5 h-5" fill={favorite ? "#F9B300" : "#fff"} />
        </button>
      </div>
      <div className="flex items-center justify-between px-6 py-4">
        <div className="flex items-center">
          <div>
            <h3 className={tw("text-lg font-semibold text-gray-900 truncate")}>{truncateText(name, 20)}</h3>
            <p className="text-sm text-gray-500 truncate">@{name.replace(/\s/g, "").toLowerCase()}</p>
          </div>
        </div>
        <button
          className={tw(
            "text-white px-4 py-2 rounded-full text-sm font-medium",
            "transition-colors w-full sm:w-auto mt-2 sm:mt-0",
            isComingSoon ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
          )}
          disabled={isComingSoon}
        >
          {isComingSoon ? "Training..." : "Let's Chat"}
        </button>
      </div>
    </div>
  );
}
