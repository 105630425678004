import { PropsWithChildren } from "react";
import Link from "next/link";
import { tw } from "../../utils/helpers";

export const NavBarItem = ({
    children,
    href,
  }: PropsWithChildren<{ href: string }>) => {
    return (
      <div
        className={tw(
          "rounded-md px-4 py-2 transition-all",
          " hover:text-lightPurple text-darkPurple",
          "font-bold text-2xl"
        )}
      >
        <Link href={href} className="drop-shadow-md flex justify-center items-center space-x-2 ">
          {children}
        </Link>
      </div>
    );
  };