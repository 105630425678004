import { SiteConfig } from "@/types/siteConfig";

export const Config : SiteConfig =
{
    termsOfUseUrl:  '/terms-of-use.pdf',
    privacyPolicyUrl: '/privacy-policy.pdf', 
    minPasswordLength: 8,
}

export const aiProfileData = {
    relationshipItems:  ["Single", "In a Relationship", "It's Complicated"],
    ageRange: ["18-25", "26-33", "34-41"],
    // explictOptions: ["PG", "PG-13", "R", "X", "XXX"],
    explictOptions: [ "PG-13", "XXX"],

}
 
export enum ExplicitLevel {
    "PG-13" = 0,
    "XXX" = 1
}