import { tw } from "@/utils/helpers";
import React from "react";
import { PropsWithChildren } from "react";
import { Footer } from "@/components/Footer/Footer";
import { NavBar } from "./NavBar/Navbar";

export default function PageWrapper({ children }: PropsWithChildren) {
  return (
    <div>
      <main
        className={tw("flex flex-col px-8 w-full min-h-screen items-center bg-offWhite", "font-fredoka font-normal ")}
      >
        <NavBar />
        {children}
      </main>
      <Footer />
    </div>
  );
}
