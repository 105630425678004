
import { Config } from "@/utils/siteConfig";
import React from "react";

const privacyPolicySrc = Config.privacyPolicyUrl;
const termsOfUseSrc = Config.termsOfUseUrl;

export const PrivacyPolicyFrame = (props: any) => {
    return <iframe src={privacyPolicySrc} width="100%" height="600px" />;
}


export const TermsOfUseFrame = (props: any)=>{
    return <iframe src={termsOfUseSrc} width="100%" height="600px" />;
}


export const TermsOfUseLink = (props: any) => {
    return  (<a href={termsOfUseSrc} target="_blank" className={props.className ? props.class : "text-blue-500" }>Terms of Use</a>)
   }

export const PrivacyPolicyLink = (props: any) => {
    return  (<a href={privacyPolicySrc} target="_blank" className={props.className ? props.class : "text-blue-500" }>Privacy Policy</a>)
   }
