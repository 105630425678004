"use client"
import { tw } from "@/utils/helpers";
import Link from "next/link";
import { Instagram, Mail, Printer, Send, Twitter, Youtube } from "react-feather";
import  Logo  from "@/assets/svg/logo.svg";
import { NavBarItem } from "../NavBar/NavbarItem";
import LogoPurple from "@/assets/Logos/LogoPurpleGreen.png";
import Image from "next/image";
import { PrivacyPolicyLink, TermsOfUseLink } from "@/components/PrivacyTermsOfService";

export function Footer() {
  return (
    <div
      className={tw(
        "flex flex-col md:flex-row items-center w-screen py-4  min-h-[2rem]   bg-lightPink",
        "justify-around justify-self-end "
      )}
    >
          <NavBarItem href="/">
            <Image src={LogoPurple} height={150} width={150} alt="Logo"></Image>
          </NavBarItem>
      <div
        className={tw(
          "flex flex-row items-center",
          "py-4 md:ml-8 w-full md:w-auto",
          "justify-center gap-12 h-full"
        )}
      >
        {/* todo: link to socials */}
        {/* <Twitter size="1.5rem" className="cursor-pointer" />
        <Send size="1.5rem" className="cursor-pointer" /> */}
        <TermsOfUseLink className="footer-link" />
        <PrivacyPolicyLink className="footer-link" />
      </div>
    </div>
  );
}
