import path from "path";
import { ExplicitLevel } from "./siteConfig";

export type Maybe<T> = T | null | undefined;

export function tw(...args: Maybe<string | boolean>[]) {
  return args.filter((a) => a && a != null).join(" ");
}

export function shortenAddress(currentAccount: string): string {
  return `${currentAccount.slice(0, 6)}...${currentAccount.slice(-4)}`;
}

export function formatBitcoin(satoshis?: number): string {
  if (!satoshis) {
    return "-";
  }
  return (satoshis / 100000000).toFixed(8);
}

export function isNullOrEmpty(str: string | undefined | null) {
  return !str || str.trim().length === 0;
}

export function getDynamicSegment(path: string) {
 return path.substring(path.lastIndexOf("/") + 1);
}

export function getDate(years: number) {
  var today = new Date();
  var pastYear = today.getFullYear() - years;
  today.setFullYear(pastYear);

  var month = today.toLocaleString('default', { month: 'long' });
  var day = today.getDate();
  var year = today.getFullYear();
  let newDate = `${month} ${day}, ${year}`;
  return newDate;
}


export function getExplicitLevel(value: string): ExplicitLevel { return ExplicitLevel[value as keyof typeof ExplicitLevel]; }
