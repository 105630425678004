import React, { useRef } from "react";
import { useRouter } from "next/navigation";
import { User } from "react-feather";
import { NavBarItem } from "../NavBar/NavbarItem";

interface ProfileMenuProps {
  address: string;
  showName?: boolean;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ address, showName }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const profileURL = `/profile/user/${address}`;
  const router = useRouter();

  // Navigate to profile on click
  const navigateToProfile = () => {
    router.push(profileURL);
  };

  return (
    <div ref={wrapperRef} className="profile-menu relative h-12 w-40" onClick={navigateToProfile}>
      <NavBarItem href="">
        <User className="h-8 w-8 rounded-full border-darkPurple border-2 p-1" />
        {showName && <h1>Profile</h1>}
      </NavBarItem>
    </div>
  );
};
