"use client";

import Link from "next/link";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { NavBarItem } from "./NavbarItem";
import { tw } from "../../utils/helpers";
import { PropsWithChildren, useRef, useState } from "react";
import { ChevronRight, Menu, Home, MessageCircle, Layers, Lock, HelpCircle } from "react-feather";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { usePathname } from "next/navigation";
import Logo from "@/assets/svg/logo.svg";
import ChatsIcon from "@/assets/svg/Chat-Icon-Square.svg";
import { useAccount } from "wagmi";
import { ProfileMenu } from "../Dropdown/ProfileDropdown";
import { useSession } from "next-auth/react";
import { LogOut } from "react-feather";
import { useDisconnect } from "wagmi";

export function NavBar() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const { isConnected, address } = useAccount();
  const disconnect = useDisconnect();
  const pathname = usePathname();
  const { data: session, status } = useSession();

  const stakingURL = `/profile/user/${address}#staking`;

  useOnClickOutside(mobileNavRef, () => {
    setMobileNavOpen(false);
  });

  return (
    <div className={tw("flex w-screen z-50")}>
      <div className={tw("flex flex-row w-full items-center shadow-sm py-4 bg-white")}>
        {/* Desktop navigation items */}
        <div className={tw("h-full mx-8 gap-4", "flex-row items-center grow basis-2", "hidden md:flex")}>
          <NavBarItem href="/">
            <Home className="h-8 w-8" />
            <h1>Home</h1>
          </NavBarItem>
          {pathname !== "/onboard" && (
            <NavBarItem href="/chat">
              <MessageCircle className="h-8 w-8" />
              <h1>Chat</h1>
            </NavBarItem>
          )}
          <NavBarItem href={stakingURL}>
            <Lock className="h-8 w-8" />
            <h1>Staking</h1>
          </NavBarItem>
          <NavBarItem href="/leaderboards">
            <Layers className="h-8 w-8" />
            <h2>Leaderboards</h2>
          </NavBarItem>
        </div>
        {/* Desktop right-aligned items */}
        <div className={tw("h-full px-6 gap-4", "flex-row items-center grow basis-2 justify-end", "hidden md:flex")}>
          {isConnected && <ProfileMenu address={address as string} showName={true} />}
          <ConnectButton />
        </div>
        {/* Mobile icons */}
        <div className={tw("flex md:hidden justify-between items-center w-full px-4")}>
          <div className={tw("flex")}>
            <NavBarItem href="/">
              <Home className="h-8 w-8" />
            </NavBarItem>
            <NavBarItem href="/chat">
              <MessageCircle className="h-8 w-8" />
            </NavBarItem>
          </div>
          <div className={tw("flex")}>
            {isConnected ? (
              <>
                <ProfileMenu address={address as string} showName={false} />
                {/* <button onClick={() => disconnect} className={tw("ml-4")}>
                  <LogOut className="h-8 w-8" />
                </button> */}
              </>
            ) : (
              <ConnectButton />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
